import 'react-dates/initialize';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { stringify } from 'querystring';
import {
  Container,
  PlantLogForm,
  TextInput,
  Label,
  MetadataContainer,
  InputControl,
  PlantLogCopyContainer,
  PlantLogHeadline,
  PlantLogContainer,
  PlantLogEntry,
  PlantLogDate,
  EntryTop,
  DeleteEntry,
} from './styles';
import moment from 'moment';
import { SubmitButton } from '../NewPlant/styles';
import { WateringCalendar } from '../WateringCalendar';
import { useContext } from '../../Context';

if (typeof window !== 'undefined') {
  require('react-dates/lib/css/_datepicker.css');
}

export function Plant() {
  const { id } = useParams();
  const [error, setError] = useState('');
  const [focused, setFocused] = useState(false);
  const [date, setDate] = useState<moment.Moment | null>(moment(new Date()));
  const [note, setNote] = useState('');
  const [state = {}, setState] = useContext();
  const { plants } = state;
  const plant = plants && id ? plants[id] : null;
  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const water = {
      date: date && date.utc().toISOString(),
      note,
    };
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/plant/${id}/water`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        credentials: 'same-origin',
        body: stringify(water),
      });
      setState({
        ...state,
        plants: {
          ...plants,
          [plant.Id]: {
            ...plant,
            waters: [...(plant.waters || []), water],
          },
        },
      });
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    if (plant && plant.name) {
      document.title = plant.name;
    }
  }, [plant]);

  if (!plant) return null;
  const { waters = [] } = plant;

  return (
    <Container>
      <MetadataContainer>
        {error && <span>error</span>}
        <h1>{plant.name}</h1>
        <p>{plant.description}</p>
        <h2>How to care for your {plant.name}</h2>
        <h3>Humidity</h3>
        <p>{plant.humidity}</p>
        <h3>Sunlight</h3>
        <p>{plant.sunlight}</p>
        <h3>Water</h3>
        <p>{plant.water}</p>
        <h3>Potting</h3>
        <h3>Sad plant signs</h3>
        <h4>Thirsty</h4>
        <h4>Overwatered</h4>
      </MetadataContainer>
      <PlantLogForm onSubmit={handleSubmit}>
        <PlantLogCopyContainer>
          <PlantLogHeadline>Water log</PlantLogHeadline>
          <p>Keep track of watering and leaf notes</p>
        </PlantLogCopyContainer>
        <InputControl>
          <Label htmlFor="date">Date</Label>
          <TextInput name="date" value={date?.format('MM/DD/YYYY')} />
        </InputControl>
        <WateringCalendar
          dates={waters.map((water: any) => moment(water.date))}
          onDateChange={(date: moment.Moment | null) => setDate(date)}
          onFocusChange={({ focused }: { focused: boolean }) => setFocused(focused)}
          date={date}
          focused={focused}
        />
        <InputControl>
          <Label htmlFor="notes">Notes</Label>
          <TextInput name="notes" onChange={e => setNote(e.target?.value)} value={note} />
        </InputControl>
        <SubmitButton type="submit" name="submit">
          Add to water log
        </SubmitButton>
        <PlantLogContainer>
          {waters.map((water: any, i: number) => {
            return (
              <PlantLogEntry key={`${plant.Id}-${i}`}>
                <EntryTop>
                  <PlantLogDate>{moment(waters.date).format('MM/DD/YY')}</PlantLogDate>
                  <DeleteEntry
                    onClick={() => {
                      fetch(`${process.env.REACT_APP_API_URL}/plant/${id}/water/${i}/delete`, {
                        method: 'DELETE',
                        credentials: 'same-origin',
                        headers: new Headers({
                          'Content-Type': 'application/x-www-form-urlencoded',
                        }),
                      }).then(res => {
                        if (res.ok) {
                          setState({
                            ...state,
                            plant: {
                              ...plant,
                              waters: [...plant.waters.slice(0, i), plant.waters.slice(i + 1)],
                            },
                          });
                        }
                      });
                    }}
                  >
                    ⓧ
                  </DeleteEntry>
                </EntryTop>
                <p>{water.note}</p>
              </PlantLogEntry>
            );
          })}
        </PlantLogContainer>
      </PlantLogForm>
    </Container>
  );
}
