import React, { useEffect } from 'react';

const Context = React.createContext<any>(null);
export const useContext = () => React.useContext(Context);

export const Provider = ({
  initialState,
  children,
}: {
  initialState: any;
  children: JSX.Element;
}) => {
  const [state, setState] = React.useState(initialState);
  useEffect(() => {
    if (initialState && !state) {
      setState(initialState);
    }
  }, [state, initialState]);
  useEffect(() => {
    async function fetchData() {
      const request = await fetch(`${process.env.REACT_APP_API_URL}/plants`);
      if (request.ok) {
        const { plants, ...state } = await request.json();
        setState({ ...state, plants: plants });
      }
    }

    if (!initialState && !state) {
      fetchData();
    }
  }, [initialState, state]);
  return <Context.Provider value={[state, setState]}>{children}</Context.Provider>;
};
