import { createGlobalStyle } from 'styled-components';

enum Colors {
  BLACK_COFFEE = '#362C28',
  TEA_GREEN = '#D4FCC3',
  CHAMPAGNE = '#FFF5B2',
  CHARTREUSE = '#EDFF86',
  ORANGE = '#F3C969',
  PURPLE = '#5438DC',
  AZURE = '#357DED',
}

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${Colors.TEA_GREEN};
    color: ${Colors.BLACK_COFFEE}
  }
  body {
    background: linear-gradient(-45deg, ${Colors.TEA_GREEN}, ${Colors.ORANGE}, ${Colors.CHAMPAGNE}, ${Colors.CHARTREUSE}, ${Colors.PURPLE});
    background-size: 800% 800%;
    animation: gradient 120s ease infinite;
  }
  
  h1 {
    font-family: Monaco, monospace;
    color: ${Colors.AZURE}
  }
  
  a {
    font-weight: bold;
    text-decoration: none;
    color: ${Colors.AZURE};
    &:hover {
      color: ${Colors.PURPLE};
    }
  }
  
  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @keyframes pulse {
    from {
      text-shadow:  0 0 0 ${Colors.TEA_GREEN},
      0 0 0 ${Colors.PURPLE};
    }
    to {
      text-shadow:  0 0 2rem ${Colors.TEA_GREEN},
      0 0 4rem ${Colors.PURPLE};
    }
  }
  .DateInput {
    width: 100%;
    overflow: hidden;
  }
  .SingleDatePickerInput {
    width: 100%;
  }
  .SingleDatePickerInput, .DateInput, .DateInput_input {
    font-size: 1rem;
    line-height: unset;
    border-radius: 3px;
  }
  // TODO: figure out why today styling doesn't apply
  .CalendarDay__today {
    box-shadow: inset 0 0 2px 1px ${Colors.AZURE};
    font-weight: bold;
  }
`;
export { Colors, GlobalStyles };
