import React, { useCallback } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from '../styles';
import { PlantType } from '../types';
import { useContext } from '../Context';

const activeClassName = 'active';

const StyledLink = styled(NavLink).attrs({
  activeClassName,
})`
  font-weight: bold;
  border-radius: 3px;
  text-decoration: none;
  padding: 2px 6px;
  color: ${Colors.PURPLE};
  font-size: 2rem;
  text-shadow: 0 0 10px white;
  transition: all 250ms ease-out;
  -webkit-text-stroke: 1px ${Colors.PURPLE};

  :hover,
  &:active,
  &:visited {
    color: ${Colors.PURPLE};
  }

  &:focus {
    //animation: pulse 0.6s 1 ease-in-out;
    //animation-fill-mode: forwards;
  }

  &.${activeClassName} {
    color: ${Colors.TEA_GREEN};
  }
`;

const ListItem = styled.li`
  display: inline-block;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 1rem;
`;

const Wrapper = styled.div`
  width: calc(100% - 2rem);
  border-radius: 5px;
  margin: 1rem;
`;

const Clover = styled.span`
  font-size: 1.4rem;
`;

const Home = styled(StyledLink)`
  margin-right: 1rem;
`;

export const Navigation = () => {
  const location = useLocation();

  const isDetailsActive = useCallback(() => {
    return location.pathname.indexOf('details/') > -1;
  }, [location.pathname]);

  const [state] = useContext();
  if (!state) return null;
  const { plants } = state;
  const keys = Object.keys(plants);
  const index = Math.floor(Math.random() * keys.length);
  const randomPlantId = plants[keys[index]]?.Id;

  return (
    <Wrapper>
      <List>
        <ListItem>
          <Home to="/" activeClassName={activeClassName} exact>
            Plants
          </Home>
        </ListItem>

        <ListItem>
          <StyledLink
            to={'/p/' + randomPlantId}
            activeClassName={activeClassName}
            isActive={isDetailsActive}
          >
            <Clover role="img" aria-label="Four-leaf clover">
              🍀
            </Clover>
          </StyledLink>
        </ListItem>
      </List>
    </Wrapper>
  );
};
