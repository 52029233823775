import styled from 'styled-components';
import { Colors } from '../../styles';
import { TrippyText } from '../../pages/Home/styles';

export const Container = styled.div`
  margin: 0 auto;
  width: 960px;
  display: flex;
  > h1,
  > h2 {
    font-weight: 200;
  }
`;

export const MetadataContainer = styled.div`
  border-radius: 3px;
  flex: 1;
  margin-right: 2rem;
`;

export const PlantLogForm = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: ${Colors.BLACK_COFFEE}33;
  padding: 2rem;
`;

export const PlantLogCopyContainer = styled.div`
  margin-bottom: 1rem;
  > p {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
`;

export const PlantLogHeadline = styled.h1`
  margin: 0;
  font-weight: bold;
`;

export const InputControl = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

export const TextInput = styled.input`
  padding: 0.5rem;
  margin-bottom: 1.25rem;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  border: 1px solid ${Colors.AZURE};
  font-size: 1rem;
  color: ${Colors.BLACK_COFFEE};
  &::placeholder {
  }
`;

export const Label = styled.label`
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0.125rem;
  color: ${Colors.BLACK_COFFEE};
`;

export const PlantLogContainer = styled.ul`
  padding-left: 0;
  list-style: none;
  max-width: 20rem;
`;

export const PlantLogEntry = styled.li`
  border-radius: 3px;
  background: ${Colors.TEA_GREEN};
  color: ${Colors.PURPLE};
  padding: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  box-shadow: 0 2px 2px 0px ${Colors.AZURE};
  > p {
    margin-bottom: 0;
  }
`;

export const PlantLogDate = styled.em`
  font-size: 0.75rem;
  font-weight: normal;
  color: ${Colors.BLACK_COFFEE};
`;

export const EntryTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DeleteEntry = styled.span`
  cursor: pointer;
  transform: translateY(-25%);
  -webkit-text-stroke: 1px ${Colors.PURPLE};
  &:hover {
    -webkit-text-stroke: 1px ${Colors.AZURE};
  }
`;
