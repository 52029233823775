import React from 'react';
import styled from 'styled-components';
import { Colors } from '../styles';

const Link = styled.a`
  color: ${Colors.AZURE};
  text-decoration: none;
  text-shadow: 0 0 10px white;
`;

const Wrapper = styled.p`
  font-weight: bold;
  font-size: 0.8rem;
  margin-top: auto;
`;

export const Footer = () => (
  <Wrapper>
    {new Date().getFullYear()} <Link href="https://felguerez.com">Antonio Felguerez</Link>
  </Wrapper>
);
