import styled, { css } from 'styled-components';
import { Colors } from '../../styles';

const StyledList = styled.ul`
  padding-inline-start: 0;
  list-style: none;
  li {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const PlantFamilyList = styled(StyledList)`
  li a {
    font-size: 2rem;
    background: -webkit-linear-gradient(
      -45deg,
      ${Colors.TEA_GREEN},
      ${Colors.ORANGE},
      ${Colors.CHAMPAGNE},
      ${Colors.CHARTREUSE}
    );
    -webkit-text-stroke: 1px ${Colors.PURPLE};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background-color 10s ease;
    &:hover {
      background: -webkit-linear-gradient(
        ${Colors.TEA_GREEN},
        ${Colors.ORANGE},
        ${Colors.CHAMPAGNE},
        ${Colors.CHARTREUSE}
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const TrippyText = css`
  text-shadow: 0 0 0.5rem white, 0 0 0.5rem ${Colors.PURPLE};
  animation: pulse 120s infinite ease-in-out;
`;

const Headline = styled.h1`
  color: ${Colors.PURPLE};
  font-size: 2.5rem;
  ${TrippyText};
  text-align: center;
`;

const Subheader = styled.h2`
  color: ${Colors.PURPLE};
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  font-family: Monaco, monospace;
  ${TrippyText}
`;

const Emoji = styled(Subheader)`
  font-size: 5rem;
`;
export { StyledList, PlantFamilyList, Subheader, Headline, Emoji };
