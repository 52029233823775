import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router';
import { Headline } from './Home/styles';

export const Details = withRouter(({ match: { params: { id } } }) => (
  <>
    <Helmet>
      <title>About: {id}</title>
    </Helmet>
    <Headline>About: {id}</Headline>
  </>
));
