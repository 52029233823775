import React, { useEffect } from 'react';
import { Headline, Emoji, PlantFamilyList } from './styles';
import { Link } from 'react-router-dom';
import { useContext } from '../../Context';

export const Home = () => {
  const [state = {}] = useContext();
  const { plants } = state;
  return (
    <div>
      <Headline>Welcome to the plant gallery</Headline>
      <Emoji>
        <span role="img" aria-label="Plant">
          🌱
        </span>
      </Emoji>
      {plants && (
        <>
          <Headline>The family</Headline>
          <PlantFamilyList>
            {Object.keys(plants).map(id => {
              const plant = plants[id];
              return (
                <li key={plant.name}>
                  <Link to={`/p/${plant.Id}`}>{plant.name}</Link>
                </li>
              );
            })}
          </PlantFamilyList>
        </>
      )}
    </div>
  );
};
