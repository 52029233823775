import styled from 'styled-components';
import { Colors } from '../../styles';

export const Content = styled.div`
  margin: 0 auto;
  width: 480px;
  > h1,
  > h2 {
    font-weight: 200;
  }
`;

export const NewTodoForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  border-radius: 5px;
  background: ${Colors.BLACK_COFFEE}33;
  padding: 2rem;
`;

export const TextInput = styled.input`
  padding: 0.5rem;
  margin-bottom: 1.25rem;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  border: 1px solid ${Colors.AZURE};
  font-size: 1rem;
  color: ${Colors.BLACK_COFFEE};
  &::placeholder {
  }
`;

export const SubmitButton = styled.button`
  padding: 1rem;
  color: #fff;
  background: linear-gradient(-45deg, ${Colors.ORANGE}, ${Colors.PURPLE}, ${Colors.AZURE});
  background-size: 800% 800%;
  animation: gradient 60s ease infinite;
  border-radius: 0.25rem;
  font-weight: bold;
  border: 1px solid ${Colors.PURPLE};
  &:hover {
    background: ${Colors.AZURE};
  }
  &[disabled] {
    cursor: not-allowed;
  }
  cursor: pointer;
`;

export const Label = styled.label`
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0.125rem;
  color: ${Colors.BLACK_COFFEE};
`;
