import { DayPickerSingleDateController, CalendarDay } from 'react-dates';
import React from 'react';
import moment from 'moment';

export function WateringCalendar(props: {
  date: moment.Moment | null;
  dates: moment.Moment[];
  onDateChange: (date: moment.Moment | null) => void;
  onFocusChange: (arg: { focused: boolean }) => void;
  focused: boolean;
}) {
  const { dates, onDateChange, onFocusChange, date, focused, ...rest } = props;
  return (
    // @ts-ignore
    <DayPickerSingleDateController
      onDateChange={onDateChange}
      numberOfMonths={1}
      key={dates.length}
      renderCalendarDay={calProps => {
        const { day, modifiers = new Set<string>() } = calProps;

        if (dates && dates.some(d => moment(d).isSame(moment(day), 'day'))) {
          modifiers.add('highlighted-calendar');
        } else {
          modifiers && modifiers.delete('highlighted-calendar');
        }
        if (day && day.isSame(new Date(), 'day')) {
          modifiers.add('today');
        } else {
          modifiers.delete('today');
        }
        return <CalendarDay {...rest} {...calProps} day={day} modifiers={modifiers} />;
      }}
    />
  );
}
