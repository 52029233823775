import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';

import { Details } from './pages/Details';
import { NotFound } from './pages/Error';
import { Home } from './pages/Home';

import { Footer } from './components/Footer';
import { Navigation } from './components/Navigation';
import { GlobalStyles } from './styles';
import { NewPlant } from './components/NewPlant';
import { Plant } from './components/Plant';
import { PlantType } from './types';
import { Provider } from './Context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

export type State = { plants?: { [Id: string]: PlantType }; plant?: PlantType };

interface Props {
  state: State;
}

const App: React.FC<Props> = (props: Props) => {
  // @TODO: `ready` is a boolean to indicate the app's data is available on the window
  // `state` could just as easily serve in its place as it's type is undefined | State;
  // both are set at the same time, so they're both available at once or both undefined
  // e.g.
  // useEffect(() => {
  //   if (!state && window.state) {
  //     setState(window.state);
  //   }
  // }, [state]);
  // if (!state) return null;
  const ready = (typeof window !== 'undefined' && window.ready) || typeof window === 'undefined';
  const [state, setState] = useState(props.state);
  useEffect(() => {
    if (!state && window.state) {
      setState(window.state);
    }
  }, [ready, state]);
  if (!ready || !state) return null;
  return (
    <Provider initialState={state}>
      <>
        <GlobalStyles />
        <Helmet>
          <title>{process.env.REACT_APP_NAME}</title>
        </Helmet>

        <Wrapper>
          <Navigation />

          <Switch>
            <Route path="/details/:id" component={Details} />
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/new">
              <NewPlant />
            </Route>
            <Route path="/p/:id/">
              <Plant />
            </Route>
            <Route component={NotFound} />
          </Switch>

          <Footer />
        </Wrapper>
      </>
    </Provider>
  );
};

export default App;
