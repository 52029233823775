import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { stringify } from 'querystring';
import { SubmitButton, Content, NewTodoForm, TextInput, Label } from './styles';

export function NewPlant() {
  const history = useHistory();
  const [error, setError] = useState('');
  const [plant, setPlant] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setPlant(event.target.value);
  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    type Value = {
      value: string;
    };
    // TODO: Solve this TS issue in a better way
    const elements = e.target.elements as HTMLCollectionOf<HTMLInputElement | HTMLButtonElement> & {
      name: Value;
      description: Value;
      sunlight: Value;
      water: Value;
      humidity: Value;
      temperature: Value;
    };
    const { name, description, sunlight, water, humidity, temperature } = elements;
    const plant = {
      name: name.value,
      description: description.value,
      sunlight: sunlight.value,
      water: water.value,
      humidity: humidity.value,
      temperature: temperature.value,
    };
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/plant`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        credentials: 'same-origin',
        body: stringify(plant),
      });
      history.push('/');
    } catch (e) {
      setError(e);
    }
  };
  useEffect(() => {
    document.title = 'New plant';
  }, []);
  return (
    <Content>
      {error && <span>error</span>}
      <h1>Tell me about your plant</h1>
      <NewTodoForm onSubmit={handleSubmit}>
        <Label htmlFor="name">Name</Label>
        <TextInput
          type="text"
          name="name"
          placeholder="Monstera Deliciosa"
          value={plant}
          onChange={handleChange}
        />
        <Label htmlFor="description">Description</Label>
        <TextInput
          type="text"
          name="description"
          placeholder="Three feet tall, very trendy, plenty of fenestration"
        />
        <Label htmlFor="sunlight">Sunlight</Label>
        <TextInput
          type="text"
          name="sunlight"
          placeholder="Thrives in bright to medium indirect light"
        />

        <Label htmlFor="water">Watering cadence</Label>
        <TextInput type="text" name="water" placeholder="Every 1-2 weeks" />

        <Label htmlFor="Humidity">Humidity</Label>
        <TextInput
          type="text"
          name="humidity"
          placeholder="Normal room humidity will do, but prefers humid conditions if possible."
        />

        <Label htmlFor="temperature">Temperature</Label>
        <TextInput type="text" name="temperature" placeholder="65°F-85°F (18°C-30°C)" />

        <SubmitButton type="submit" name="submit" disabled={!plant}>
          submit
        </SubmitButton>
      </NewTodoForm>
    </Content>
  );
}
